import React from 'react';
import PropTypes from 'prop-types';
import {styled, darken, lighten} from '@mui/material/styles';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const classes = {
  root: 'ButtonGroup-root',
  button: 'ButtonGroup-button',
  buttonContent: 'ButtonGroup-buttonContent'
};

const Root = styled(ToggleButtonGroup,
  {shouldForwardProp: (prop) => prop !== 'color' && prop !== 'disabled' && prop !== 'variant'}
)(({color, disabled, variant, theme}) => {
  const buttonColor = color || theme.palette.primary.main;
  return {
    '&.ButtonGroup-root': {
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    '& .ButtonGroup-button': {
      boxSizing: 'border-box',
      backgroundColor: variant === 'outlined' ? 'white' : disabled ? theme.palette.grey[200] : buttonColor,
      borderColor: variant === 'outlined' ? theme.palette.grey[400] : 'transparent',
      '&.MuiButtonBase-root': {
        filter: variant === 'outlined' && 'saturate(0%) opacity(40%)',
        border: variant === 'outlined' ? `1px solid ${theme.palette.grey[200]}` : disabled ? 'transparent' : `1px solid ${darken(buttonColor, 0.5)}`,
        '&:hover': {
          border: disabled ? undefined : variant === 'outlined' && `1px solid ${buttonColor}`,
        },
      },
      '&:hover': {
        backgroundColor: variant === 'outlined' ? (disabled ? 'white' : lighten(buttonColor, 0.85)) : (disabled ? theme.palette.grey[200] : darken(buttonColor, 0.25)),
      },
      '&.Mui-selected': {
        filter: 'saturate(100%) opacity(100%)',
        boxSizing: 'border-box',
        borderColor: variant === 'contained' ? 'transparent' : disabled ? theme.palette.grey[400] : buttonColor,
        borderWidth: variant === 'contained' ? '1px' : '2px',
        backgroundColor: variant === 'outlined' ? (disabled ? theme.palette.grey[200] : lighten(buttonColor, 0.85)) : (disabled ? theme.palette.grey[300] : darken(buttonColor, 0.25)),
        '&:hover': {
          borderColor: variant === 'contained' ? 'transparent' : disabled ? theme.palette.grey[400] : buttonColor,
          borderWidth: variant === 'contained' ? '1px' : '2px',
          backgroundColor: variant === 'outlined' ? (disabled ? theme.palette.grey[200] : lighten(buttonColor, 0.85)) : (disabled ? theme.palette.grey[300] : darken(buttonColor, 0.25)),
        },
      },
    },
    '& .ButtonGroup-buttonContent': {
      lineHeight: 1,
      color: variant === 'contained' ? 'white' : disabled ? '#b2b2b2' : buttonColor,
    }
  };
});

const ButtonGroup = ({selectedItems, items, onItemClick, disabled, color, variant, sx}) => {
  const handleItemClick = (e, selectedItemId) => onItemClick && onItemClick(selectedItemId);

  return <Root
    size='small'
    value={selectedItems}
    onChange={disabled ? undefined : handleItemClick}
    className={classes.root}
    color={color}
    disabled={disabled}
    variant={variant}
    sx={sx}
  >
    {items.map(item =>
      <ToggleButton
        key={item.id}
        value={item.id}
        className={classes.button}
        disableRipple={disabled}
      >
        <Tooltip title={item.label || ''}>
          <Box display="flex" flexDirection="row" className={classes.buttonContent}>
            {item.content}
          </Box>
        </Tooltip>
      </ToggleButton>
    )}
  </Root>;
};

ButtonGroup.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    label: PropTypes.string
  })).isRequired,
  onItemClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  sx: PropTypes.object,
};

ButtonGroup.defaultProps = {
  disabled: false,
  variant: 'contained'
};

export default ButtonGroup;