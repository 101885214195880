import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Map from '@geomatico/geocomponents/Map';

import {INITIAL_VIEWPORT, LAYERS} from '../config';

const buildPaint = (type, color) => {
  switch (type) {
  case 'circle':
    return {
      'circle-color': color,
      'circle-radius': 6,
    };
  case 'line':
    return {
      'line-color': color,
      'line-width': 3
    };
  case 'fill':
    return {
      'fill-opacity': 0.5,
      'fill-color': color,
      'fill-outline-color': '#000000'
    };
  case 'symbol':
    return {
      'text-color': color,
    };
  }
};

const buildLayout = (name, type, selectedVariables, selectedSwitch) => {
  switch (type) {
  case 'symbol':
    return {
      //'text-field': '\u29EB',
      'text-field': '\u2666',
      'text-font': ['Arial-Regular'],
      'text-size': 20,
      'text-anchor': 'center',
      'text-justify': 'center',
      'symbol-placement': 'point',
      'text-allow-overlap': true,
      'visibility': selectedVariables.includes(name)  || selectedSwitch.includes(name) ? 'visible' : 'none'
    };
  default:
    return {
      'visibility': selectedVariables.includes(name)  || selectedSwitch.includes(name) ? 'visible' : 'none'
    };
  }
};

const MainContent = ({mapStyle, selectedVariables, selectedSwitch}) => {
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  let layerNames = LAYERS;

  const sources = useMemo(() => (
    layerNames.reduce((acc, {name}) => {
      acc[name] = {
        type: 'geojson',
        data: `https://espeleoloxia.nevian.cloud/geoserver/atlas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=atlas:${name}&outputFormat=application%2Fjson`,
      };
      return acc;
    }, {})
  ), []);
  
  const layers = useMemo(() => (
    layerNames.map(({name, type, color}) => (
      {
        'id': name,
        'type': type,
        'source': name,
        'paint': buildPaint(type, color),
        'layout': buildLayout(name, type, selectedVariables, selectedSwitch)
      }
    )
    )), [selectedVariables, selectedSwitch]);

  return <Map
    mapStyle={mapStyle}
    sources={sources}
    layers={layers}
    viewport={viewport}
    onViewportChange={setViewport}
  />;
};

MainContent.propTypes = {
  mapStyle: PropTypes.string.isRequired,
  selectedVariables: PropTypes.arrayOf(PropTypes.string),
  selectedSwitch: PropTypes.arrayOf(PropTypes.string),
};

export default MainContent;
