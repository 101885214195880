import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ButtonGroupList from './ButtonGroupList';

function VariablePicker({selectedVariables, onSelectedVariableChange}) {
  const {t} = useTranslation();

  const categories = ['FURNAS', 'KARST','PARAKARST','PSEUDOKARST', 'ARTIFICIALES'];

  const handleChange = (value) => onSelectedVariableChange(value);
  const translatedCategories = categories.map(category => ({id: category, description: <div>{t(category)}</div>}));

  return (
    <ButtonGroupList
      onItemClick={handleChange}
      selectedVariables={selectedVariables}
      categories={translatedCategories}
      sx={{mt: 3, fontSize: '14px', '& .ButtonGroupList-description': {marginRight: 0} }}
    />
  );
}

VariablePicker.propTypes = {
  selectedVariables: PropTypes.array.isRequired,
  onSelectedVariableChange: PropTypes.func.isRequired,
};

export default VariablePicker;
