import React from 'react';
import Box from '@mui/material/Box';

const Sponsors = () => {
  return <Box sx={{display: 'flex', alignItems: 'flex-end', flexGrow: 2, padding: '12px'}}>
    <Box sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap'}}>
      <img src='images/patrocina.png' width='100%' alt=''/>
    </Box>
  </Box>;
};

export default Sponsors;