import React from 'react';
import PropTypes from 'prop-types';
import BaseMapList from '@geomatico/geocomponents/BaseMapList';
import SwitchPad from '@geomatico/geocomponents/SwitchPad';
import SectionTitle from './SectionTitle';
import VariablePicker from './VariablePicker';
import styled from '@mui/styles/styled';
import {LAYERS, MAPSTYLES} from '../config';
import {useTranslation} from 'react-i18next';
import Geomatico from './Geomatico';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Sponsors from './Sponsors';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '12px',
});

const SidePanelContent = ({mapStyle, onMapStyleChanged, selectedVariables, onVariableChanged, selectedSwitch, onSelectedSwitchChanged}) => {
  const {t} = useTranslation();
  const categoriesSwitchPad = LAYERS.filter(el => !el.isMain).map(el => ({...el, id: el.name, label: t(el.name)}));

  const handleVariableChange = variable => variable && onVariableChanged(variable);

  return <Stack sx={{height: '100%', overflow: 'hidden'}}>
    <ScrollableContent>
      <SectionTitle titleKey='baseMapStyle'/>
      <BaseMapList
        styles={MAPSTYLES}
        selectedStyleId={mapStyle}
        onStyleChange={onMapStyleChanged}
      />

      <SectionTitle titleKey="cavities"/>
      <VariablePicker
        selectedVariables={selectedVariables}
        onSelectedVariableChange={handleVariableChange}
      />

      <SectionTitle titleKey="otherVariable" sx={{mt: 3}} />

      <SwitchPad
        categories={categoriesSwitchPad}
        selected={selectedSwitch}
        onSelectionChange={onSelectedSwitchChanged}
        sx={{'& .SwitchPad-item': {padding: '5px 0px'} }}
      />

    </ScrollableContent>
    <Sponsors />
    <Geomatico/>
  </Stack>;
};

SidePanelContent.propTypes = {
  mapStyle: PropTypes.string.isRequired,
  onMapStyleChanged: PropTypes.func.isRequired,
  selectedVariables: PropTypes.arrayOf(PropTypes.string),
  onVariableChanged: PropTypes.func,
  selectedSwitch: PropTypes.arrayOf(PropTypes.string),
  onSelectedSwitchChanged: PropTypes.func
};

export default SidePanelContent;

