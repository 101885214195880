import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonGroup from './ButtonGroup';
import {STATES} from '../config';

const classes = {
  root: 'ButtonGroupList-root',
  categoryItem: 'ButtonGroupList-categoryItem',
  description: 'ButtonGroupList-description',
};

const Root = styled('div')({
  '& .ButtonGroupList-categoryItem': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '8px 0'
  },
  '& .ButtonGroupList-description': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    marginRight: '16px'
  }
});

const ButtonGroupList = ({selectedVariables, categories, onItemClick, buttonGroupColor, buttonGroupVariant, disabled, sx}) => {
  const handleItemClick = (value) => onItemClick(value);

  return <Root className={classes.root} sx={sx}>
    {
      categories.map(({id, description}) => {
        const buttonGroupItems = STATES.map(el => ({...el, id: (el.id + '.'+ id)}));
        return <Box className={classes.categoryItem} key={id}>
          <div className={classes.description}>
            {description}
          </div>
          <ButtonGroup
            items={buttonGroupItems}
            onItemClick={(optionId) => handleItemClick(optionId, id)}
            color={buttonGroupColor}
            variant={buttonGroupVariant}
            selectedItems={selectedVariables}
            disabled={disabled}
          />
        </Box>;
      })
    }
  </Root>;
};

ButtonGroupList.propTypes = {
  selectedVariables: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.element.isRequired
  })).isRequired,
  onItemClick: PropTypes.func,
  buttonGroupColor: PropTypes.string,
  buttonGroupVariant: PropTypes.oneOf(['contained', 'outlined']),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

ButtonGroupList.defaultProps = {
  buttonGroupVariant: 'contained',
  disabled: false
};

export default ButtonGroupList;