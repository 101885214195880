import React, {useState} from 'react';

import Layout from '../components/Layout';
import SidePanelContent from '../components/SidePanelContent';
import MainContent from '../components/MainContent';

import {INITIAL_MAPSTYLE_URL, INITIAL_SELECTED_LAYERS} from '../config';

const Map = () => {
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);

  const [selectedVariables, setSelectedVariables] = useState(INITIAL_SELECTED_LAYERS);
  const [selectedSwitch, setSelectedSwitch] = useState([]);

  const sidePanelContent = <SidePanelContent
    mapStyle={mapStyle}
    onMapStyleChanged={setMapStyle}
    selectedVariables={selectedVariables}
    onVariableChanged={setSelectedVariables}
    selectedSwitch={selectedSwitch}
    onSelectedSwitchChanged={setSelectedSwitch}
  />;

  const mainContent = <MainContent
    mapStyle={mapStyle}
    selectedVariables={selectedVariables}
    selectedSwitch={selectedSwitch}
  />;

  return <Layout
    sidePanelContent={sidePanelContent}
    mainContent={mainContent}
  />;
};

export default Map;
