import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsOffIcon from '@mui/icons-material/GpsOff';

export const DRAWER_WIDTH = 330;
export const SM_BREAKPOINT = 600;
export const MINI_SIDE_PANEL_WIDTH = 61;
export const MINI_SIDE_PANEL_DENSE_WIDTH = 45;

export const MINISIDEPANEL_CONFIG = [
  {id: 'mapView', route: '../map', label: 'map', content: <MapIcon/>},
];

export const INITIAL_VIEWPORT = {
  latitude: 42.771211,
  longitude: -7.814026,
  zoom: 8,
  bearing: 0,
  pitch: 0
};

export const MAPSTYLES = [
  {
    'label': 'Hibrid',
    'thumbnail': 'https://openicgc.github.io/img/orto.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/hibrid.json',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'OSM Bright',
    'thumbnail': 'https://openicgc.github.io/img/osm-bright.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'Positron',
    'thumbnail': 'https://openicgc.github.io/img/positron.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/positron.json',
    'firstTopLayer': 'place_other'
  },
  {
    'label': 'Full Dark',
    'thumbnail': 'https://openicgc.github.io/img/fulldark.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/fulldark.json',
    'firstTopLayer': 'place-other'
  }
];

export const INITIAL_MAPSTYLE_URL = MAPSTYLES[1].id;
export const INITIAL_SELECTED_LAYERS = [
  'REF.FURNAS',
  'REF.KARST',
  'REF.PARAKARST',
  'REF.PSEUDOKARST',
  'REF.ARTIFICIALES'
];

export const LAYERS = [
  {
    name: 'REF.FURNAS',
    type: 'circle',
    color: '#ffffff',
    isMain: true,
  },
  {
    name: 'REF.KARST',
    type: 'circle',
    color: '#579d1d',
    isMain: true,
  },
  {
    name: 'REF.PARAKARST',
    type: 'circle',
    color: '#fed221',
    isMain: true,
  },
  {
    name: 'REF.PSEUDOKARST',
    type: 'circle',
    color: '#014586',
    isMain: true,
  },
  {
    name: 'REF.ARTIFICIALES',
    type: 'circle',
    color: '#fe420f',
    isMain: true,
  },
  {
    name: 'ERR.FURNAS',
    type: 'symbol',
    color: '#ffffff',
    isMain: true,
  },
  {
    name: 'ERR.KARST',
    type: 'symbol',
    color: '#579d1d',
    isMain: true,
  },
  {
    name: 'ERR.PARAKARST',
    type: 'symbol',
    color: '#fed221',
    isMain: true,
  },
  {
    name: 'ERR.PSEUDOKARST',
    type: 'symbol',
    color: '#014586',
    isMain: true,
  },
  {
    name: 'ERR.ARTIFICIALES',
    type: 'symbol',
    color: '#fe420f',
    isMain: true,
  },
  {
    name: 'REF.ZT',
    type: 'fill',
    color: '#FABADA',
    isMain: false,
  },
  {
    name: 'REF.MODELOS',
    type: 'fill',
    color: '#FABADA',
    isMain: false,
  }
];

export const STATES = [{
  id: 'REF',
  content: <GpsFixedIcon/>,
  label: 'GPS válido'
}, {
  id: 'ERR',
  content: <GpsOffIcon/>,
  label: 'GPS no verificado'
}];
